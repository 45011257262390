<template>
  <VDataTable
    class="elevation-1"
    :headers="headers"
    :items="accounts"
    :loading="loading"
  >
    <template #top>
      <VToolbar flat>
        <VToolbarTitle>Аккаунты</VToolbarTitle>

        <VSpacer />

        <slot name="topActions" />
      </VToolbar>
    </template>

    <!-- TODO: перенести в экшены, по клику активировать / деактивировать -->
    <template #item.active="{ item }">
      <VIcon :color="item.active ? 'success' : 'warning'">
        fal {{ item.active ? 'fa-check-circle' : 'fa-times-circle' }}
      </VIcon>
    </template>

    <template #item.actions="{ item }">
      <slot
        name="rowActions"
        :item="item"
      />
    </template>
  </VDataTable>
</template>

<script>
export default {
  name: 'AccountsTable',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    accounts: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Название',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Активный',
          align: 'left',
          sortable: true,
          value: 'active',
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'actions',
        },
      ],
    };
  },
};
</script>
