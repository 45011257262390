<template>
  <TTView>
    <VRow>
      <VCol>
        <VExpansionPanels
          v-model="panel"
          class="py-3"
        >
          <VExpansionPanel>
            <VExpansionPanelHeader
              expand-icon="fal fa-chevron-down"
            >
              <h2 class="font-weight-bold">
                Новый аккаунт
              </h2>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <VForm
                ref="form"
              >
                <VContainer fluid>
                  <VRow
                    align="center"
                  >
                    <VCol md="6">
                      <div>
                        <strong>Имя</strong>
                      </div>
                      <VTextField
                        v-model="entity.name"
                        :counter="maxNameSize"
                      />
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol md="4">
                      <VBtn
                        large
                        depressed
                        color="primary"
                        class="mr-3"
                        :disabled="!isValidCreateForm"
                        @click="save"
                      >
                        Создать аккаунт
                      </VBtn>
                      <VBtn
                        large
                        text
                        depressed
                        @click="cancel"
                      >
                        Отмена
                      </VBtn>
                    </VCol>
                  </VRow>
                </VContainer>
              </VForm>
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCol>
    </VRow>

    <VRow>
      <VCol>
        <AccountsTable
          :accounts="entitiesList"
          :loading="loading"
        >
          <template #rowActions="{ item }">
            <TTTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  color="blue"
                  class="mx-1"
                  :to="{ name : Names.R_ACCOUNT, params : { accountId : item.id }}"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-eye
                  </VIcon>
                </VBtn>
              </template>

              <span>Просмотр</span>
            </TTTooltip>

            <TTTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  color="orange"
                  class="mx-1"
                  :disabled="!item.active"
                  :to="{ name : Names.R_ACCOUNT_EDIT, params : { id : item.id }}"
                  v-bind="attrs"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-edit
                  </VIcon>
                </VBtn>
              </template>

              <span>Редактирование</span>
            </TTTooltip>

            <TTTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  class="mx-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleActivation(item.active, item.id)"
                >
                  <VIcon
                    small
                    :color="item.active ? 'red' : 'green'"
                  >
                    fal {{ item.active ? 'fal fa-lock' : 'fal fa-unlock' }}
                  </VIcon>
                </VBtn>
              </template>

              <span>{{ item.active ? 'Деактивировать' : 'Активировать' }}</span>
            </TTTooltip>

            <TTTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="green"
                  class="mx-1"
                  v-bind="attrs"
                  :to="{ name : Names.R_ACCOUNT_USER_ADD, params : { accountId : item.id } }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-user-plus
                  </VIcon>
                </VBtn>
              </template>

              <span>Добавить Администратора</span>
            </TTTooltip>

            <TTTooltip left>
              <template #activator="{ on, attrs }">
                <VBtn
                  icon
                  small
                  dark
                  exact
                  color="red"
                  class="mx-1"
                  v-bind="attrs"
                  :to="{ name : Names.R_ACCOUNT_USER_REMOVE, params : { accountId : item.id } }"
                  v-on="on"
                >
                  <VIcon small>
                    fal fa-user-minus
                  </VIcon>
                </VBtn>
              </template>

              <span>Удалить Администратора</span>
            </TTTooltip>
          </template>
        </AccountsTable>
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import AccountsTable from '../../../components/accounts/accounts/AccountsTable.vue';

export default {
  name: 'AccountList',

  components: {
    AccountsTable,
  },

  inject: ['Names'],

  data() {
    return {
      entity: {
        name: '',
      },
      entitiesList: [],
      loading: false,
      panel: undefined,
      maxNameSize: 700,
    };
  },

  computed: {
    // TODO: временное решение, как добавится валидация, поменять на rules
    isValidCreateForm() {
      return this.isValidName;
    },
    isValidName() {
      return (this.entity.name?.length <= this.maxNameSize && this.entity.name);
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;
        const [accountsResponse] = await Promise.all([
          this.$di.api.Account.indexAccount(),
        ]);

        this.entitiesList = accountsResponse.accounts || [];
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    cancel() {
      this.$refs.form.reset();
    },

    save() {
      this.$di.api.Account.createAccount({ account: this.entity })
        .catch(this.$di.notify.errorHandler)
        .then(this.cancel)
        .then(this.fetch);
    },

    handleActivation(active, id) {
      if (active) {
        this.$di.api.Account.deactivateAccount({ id })
          .then(this.fetch)
          .catch(this.$di.notify.errorHandler);
      } else {
        this.$di.api.Account.activateAccount({ id })
          .then(this.fetch)
          .catch(this.$di.notify.errorHandler);
      }
    },
  },
};
</script>
